/* ===================================
    About
====================================== */

/*
    Theme Name: MegaOne
    Theme URI:
    Author: Themes Industry
    Author URI:
    Description: One Page Parallax Template
    Tags: one page, multipurpose, parallax, creative, html5
 */

/* ===================================
    Table of Content
====================================== */

/*  - Fonts
    - General
    - Header And Nav-Bar
    - Slider
    - About
    - Services
    - Gallery
    - Counter
    - Video Model
    - Testimonials
    - Blog
    - Contact
    - Google-Map
    - Footer
    - Loader
    - Media Queries   */

/* ===================================
    Google font
====================================== */
@import url('https://fonts.googleapis.com/css2?family=Quicksand&family=Raleway&display=swap');

.main-font{font-family: 'Quicksand', sans-serif;}
.alt-font{font-family: 'Quicksand', sans-serif;}

/* ===================================
    Scrollbar
====================================== */

::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    width: 8px;
    background:white;
    border-left: 0 solid white;
    border-right: 0 solid white;
}
::-webkit-scrollbar-thumb {
    background: #ec6d10;
    width: 0;
    height: 25%;
    transition: .5s ease;
    cursor: pointer;
}

/* ===================================
   Scroll Top
====================================== */
.go-top {
    position: fixed;
    cursor: pointer;
    bottom: 100px;
    right: 20px;
    color: #ffffff;
    background-size: 200% auto;
    background: #ec6d10;
    border-radius: 50%;
    z-index: 4;
    width: 50px;
    text-align: center;
    height: 50px;
    line-height: 42px;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: .9s;
    -o-transition: .9s;
    transition: .9s;
    -webkit-transform: translateY(150px);
    -ms-transform: translateY(150px);
    transform: translateY(150px);
}

.go-top.active {
    -webkit-animation: slideInRight 1s;
    animation: slideInRight 1s;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}

.go-top i {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: 0 auto;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.go-top i:last-child {
    opacity: 0;
    visibility: hidden;
    top: 60%;
}

.go-top::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: 200% auto;
    background: #ec6d10;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50%;
}

.go-top:hover, .go-top:focus {
    color: #ffffff;
    background-position: right center;
}

.go-top:hover::before, .go-top:focus::before {
    opacity: 1;
    visibility: visible;
}

.go-top:hover i:first-child, .go-top:focus i:first-child {
    opacity: 0;
    top: 0;
    visibility: hidden;
}

.go-top:hover i:last-child, .go-top:focus i:last-child {
    opacity: 1;
    visibility: visible;
    top: 50%;
}



/* ===================================
    Image Hover
====================================== */

.hover-effect::before,
.hover-effect::after {
    content: "";
    background: #fff;
    height: 0;
    width: 0;
    z-index: 1;
    position: absolute;
    -webkit-transition-duration: 1.3s !important;
    -o-transition-duration: 1.3s;
    transition-duration: 1.3s !important;
}
.hover-effect::before {
    right: 0;
    opacity: 1;
    bottom: 0;

}
.hover-effect::after {
    top: 0;
    opacity: .7;
    left: 0;
}
.hover-effect:hover::after, .hover-effect:hover::before {
    height: 100%;
    opacity: 0;
    width: 100%;
}

/* Use in BLog */
.image {
    overflow: hidden;
}
a.image{
    display: block;
}
.image img {
    width: 100%;
    height: 100%;
    display: block;
}
.image, .image img {
    position: relative;
}
/* Use in BLog */
.image {
    overflow: hidden;
}
a.image{
    display: block;
}
.image img {
    width: 100%;
    height: 100%;
    display: block;
}
.image, .image img {
    position: relative;
}

/* ===================================
    General
====================================== */

body {position: relative; background-color: black; font-family: 'Quicksand', sans-serif; font-size: 16px; color: #ffffff; font-weight:300; line-height:32px; width: 100%; overflow-x: hidden}
a, input, button, .navbar, .fixedmenu, .tp-bullet, .owl-dot, .owl-prev, .owl-next {transition: ease-in-out 0.2s;-webkit-transition: ease-in-out 0.2s;-moz-transition: ease-in-out 0.2s;-ms-transition: ease-in-out 0.2s;-o-transition: ease-in-out 0.2s;}
section,.section-padding {position: relative;overflow: hidden;}
a {color:inherit;text-decoration: none;}
.rev_slider_wrapper{width: 100% !important;}
.btn.focus, .btn:focus{-webkit-box-shadow: none;box-shadow: none;}
p:last-child{margin-bottom: 0 !important; font-family: 'Open Sans', sans-serif;}
.fa, .far, .fas{display: inline;}
a:active, a:hover{color: #ec6d10;}
li{list-style: none;text-decoration: none;}
blockquote {border-left: 2px solid;padding: 10px 15px 10px 35px;margin: 25px 0;font-size: 18px;line-height: 28px;margin-top: 32px;margin-bottom: 32px;}
blockquote > cite {font-weight: 400;font-size: 14px;display: block;}
.hidden{display: none;}
section{padding: 7.5rem 0;}

/* transition none */
.no-transition *{transition-timing-function:initial; -moz-transition-timing-function:initial; -webkit-transition-timing-function:initial; -o-transition-timing-function:initial; -ms-transition-timing-function:initial; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s; -ms-transition-duration: 0s;}

/* overlay */
.bg-overlay{position: absolute;top: 0;left: 0;width: 100%;height: 100%;}

/* opacity */
.opacity-full{opacity: 1;}
.opacity-1{opacity: .1;}
.opacity-2{opacity: .2;}
.opacity-3{opacity: .3;}
.opacity-4{opacity: .4;}
.opacity-5{opacity: .5;}
.opacity-6{opacity: .6;}
.opacity-7{opacity: .7;}
.opacity-8{opacity: .8;}
.opacity-9{opacity: .9;}

/*font-weight*/
.font-weight-100{font-weight: 100;}
.font-weight-200{font-weight: 200;}
.font-weight-300{font-weight: 300;}
.font-weight-300{font-weight: 400;}
.font-weight-500{font-weight: 500;}
.font-weight-600{font-weight: 600;}
.font-weight-700{font-weight: 700;}

/* button */
.btn {-webkit-appearance: initial; overflow: hidden;position: -webkit-sticky;position: sticky; z-index: 2; display:inline-block; font-size: 16px; border:2px solid transparent; letter-spacing: .5px; line-height: inherit; border-radius: 0; text-transform:capitalize; width: auto;font-family: 'Quicksand', sans-serif; font-weight: bold; -webkit-transition: all .5s ease;-o-transition: all .5s ease !important;transition: all .5s ease !important;}
/* Red button */
.btn.btn-red {background: #ec6d10 ; border-color: #ec6d10; color: #ffffff }
.btn.btn-red:hover {background: #ec6d10!important; border-color: #ec6d10; color: #ffffff!important}
.btn.btn-blue {background: #ec6d10 ; border-color: #ec6d10; color: #ffffff }
.btn.btn-blue:hover {background: #ec6d10!important; border-color: #ec6d10; color: #ffffff!important}

/* button size */
.btn.btn-small {font-size:12px; padding: 4px 1.8em;}
.btn.btn-medium {font-size:16px; padding: 10px 48px; line-height: 1.8em}
.btn.btn-large {font-size:16px;  padding: 14px 30px; line-height: 1.8em;}
.btn.btn-rounded {border-radius: 50px;}

/* Text color */
.text-blue {color: white;}
.text-red {color: #ec6d10;}
.text-grey {color: #464646;}
.text-white {color: #ffffff;}
.text-black {color: #000000;}

/* Background color */
.bg-red {background: #ed2d34;}
.bg-black {background: #000000;}
.bg-transparent {background: transparent;}

.margin-top {margin-top: 30px;}
.margin-bottom {margin-bottom: 30px;}

/*Social Icon Hover Bg Hover */
.social-icon:hover{color:#ffffff !important; background: #ec6d10 !important; -webkit-transition: .3s ease-in-out;-o-transition: .3s ease-in-out;transition: .3s ease-in-out;}

/* Social Icons Background */
.facebook-bg-hvr:hover{background:#4267B2 !important;color: #fff !important;border: 1px solid #4267B2 !important;}
.twitter-bg-hvr:hover{background:#1DA1F2 !important;color: #fff !important;border: 1px solid #1DA1F2 !important;}
.instagram-bg-hvr:hover{background:#C32AA3 !important;color: #fff !important;border: 1px solid #C32AA3 !important;}
.linkedin-bg-hvr:hover{background:#0077B5 !important;color: #fff !important;border: 1px solid #0077B5 !important;}
.pinterest-bg-hvr:hover{background:#BD081C !important;color: #fff !important;border: 1px solid #BD081C !important;}
.google-bg-hvr:hover{background:#DB4437 !important;color: #fff !important;border: 1px solid #DB4437 !important;}
.rss-bg-hvr:hover{background:#ee802f !important;color: #fff !important;border: 1px solid #DB4437 !important;}

/* ===================================
       Header
====================================== */
.navbar {
    padding-bottom: 0;
    padding-top: 0;
    z-index: 9;
    background: black;
}

.center-brand {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.center-brand, .transparent-bg {
    left: 0;
    top: 0;
}

.center-brand, .transparent-bg,
.fixed-bottom, .bg-transparent-light,
.bg-transparent-white,
.bg-invisible, .boxed-nav {
    position: absolute;
    width: 100%;
}

.bg-invisible .container {
    opacity: 0;
    visibility: hidden;
}

.fixed-bottom {
    bottom: 0;
}

.bg-white {
    position: relative;
}

.bg-white,
.boxed-nav .container {
    background: #fff;
}

.boxed-nav {
    top: 20px;

}

.fixedmenu {
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    position: fixed;
    margin: 0;
    padding-bottom: 0;
    padding-top: 0;
    z-index: 1002;
    -webkit-animation-name: animationFade;
    animation-name: animationFade;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fixedmenu .navbar-brand {
    top: 50% !important;
}

.fixed-bottom.fixedmenu{
    bottom: auto;
    -webkit-animation: none;
    animation: none;
}

.bg-invisible.fixedmenu .container {
    opacity: 1;
    visibility: visible;
}

@-webkit-keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
@keyframes animationFade {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
    }
    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

.nav-whitebg {
    background: #fff;
    -webkit-transition: all .9s ease;
    -o-transition: all .9s ease;
    transition: all .9s ease;
}

.navbar.sidebar-nav {
    background: #fff;
    border: none;
    display: inline-table;
    width: 290px;
    border-radius: 0;
    position: fixed;
    top: 0;
    z-index: 110;
    height: 100%;
    padding: 3rem 2rem;
    border-right: 1px solid rgba(0,0,0,.07);
    left: 0;
    overflow-x: hidden;
}

.navbar-brand  {
    margin: 16px 0;
    padding: 0;
    width: 10%;
    position: relative;
}
.ml-13 { margin-left: 13.5rem;}
.fixedmenu .navbar-brand {
    max-width: 100%;
}

.center-brand .navbar-brand {
    position: absolute;
    left: 0%;
    right: 0;
    margin: 0 auto;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0;
}

.navbar-brand > img {
    width: 100%;
}

.navbar.fixedmenu .logo-default,
.navbar  .logo-scrolled{
    display: none;
}

.navbar.fixedmenu .logo-scrolled,
.navbar .logo-default{
    display: inline-block;
}

.navbar.sidebar-nav .navbar-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

.navbar-nav .nav-item {
    margin: 16px 5px;
    position: relative;
}

.navbar.sidebar-nav .navbar-nav .nav-item {
    margin: 10px 0;
}

.navbar-nav .nav-item:first-child {
    margin-left: 0;
    padding-left: 20px;
}

.navbar-nav .nav-item:last-child {
    margin-right: 0;
}

.navbar-nav .nav-link {
    margin: 0 1rem !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
    padding: 0;
    text-decoration: none;
    font-family: 'Quicksand', sans-serif;
    text-transform: capitalize;
    font-size: 17px;
    color: #ffffff;
    position: relative;
    overflow: hidden;
    font-weight: bold;
}

.navbar-collapse {
    background: transparent;
}

.sidebar-nav .navbar-nav .nav-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.square-layout .navbar-nav .nav-link {
    border-radius: 0;
    border: 1px solid transparent;
}

.center-brand .navbar-nav .nav-link,
.transparent-bg .navbar-nav .nav-link,
.fixed-bottom .navbar-nav .nav-link{
    color: #ffffff;
}

.center-brand.fixedmenu .navbar-nav .nav-link {
    color: #ffffff;
}

.center-brand.fixedmenu , .center-brand.fixedmenu .navbar-collapse {
    background: #000000;
}

.center-brand .navbar-nav .nav-link:first-of-type {
    background: rgba(0,0,0,0);
}

.center-brand .navbar-nav .nav-link:hover::after {
    content: "";
    position: absolute;
    background: #fff;
    display: inline-block;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}
.nav-bottom-line .navbar-nav .nav-link.active {
    color: #ec6d10;
}
 .nav-bottom-line .navbar-nav .nav-link::after {
        content: "";
    position: absolute;
    background: #ec6d10;
    display: inline-block;
    width: 0;
    height: 3px;
    bottom: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}
.nav-bottom-line .navbar-nav .nav-link:hover::after {
    content: "";
    position: absolute;
    background: #ec6d10;
    display: inline-block;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}

/* Toggle Responsive */
.navbar-toggler {
    position: relative;
    border: none;
    border-radius: 0;
}

.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

.navbar-toggler span {
    display: block;
    background: #fff;
    height: 2px;
    width: 25px;
    margin-top: 4px;
    margin-bottom: 4px;
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    position: relative;
    left: 0;
    opacity: 1;
}

.bg-white .navbar-toggler span,
.bg-transparent-light .navbar-toggler span,
.bg-invisible .navbar-toggler span,
.navbar.sidebar-nav .navbar-toggler span,
.boxed-nav .navbar-toggler span {
    background: #212331;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
    -webkit-transition: transform .35s ease-in-out;
    -o-transition: transform .35s ease-in-out;
    -webkit-transition: -webkit-transform .35s ease-in-out;
    transition: -webkit-transform .35s ease-in-out;
    -o-transition: -o-transform .35s ease-in-out;
    transition: transform .35s ease-in-out;
    transition: transform .35s ease-in-out, -webkit-transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 3px;
    visibility: hidden;
    background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
    opacity: 0.9;
}

.top-personal-info{
    padding-left: 0;
}
.top-personal-info {
    list-style: none;
    display: inline-block;
}

.navbar-social a {
    justify-content: center;
    height: 30px;
    width: 30px;
    position: relative;
    overflow: hidden;
    align-items: center;
    display: flex;
    text-align: center;
    border-radius: 50px;
    color: #ffffff;
    font-size: 14px;
    margin: 2px;
    border: 1px solid transparent;
    transition: .5s;
}

.navbar-social a:hover {
    color: #000000;
    transition: .5s;
}

/* ===================================
       Side Menu
====================================== */
.side-menu {
    width: 40%;
    position: fixed;
    right: -1px;
    top: 0;
    background: #000000;
    z-index: 1032;
    height: 100%;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    -o-transition: -o-transform .5s ease;
    -o-transition: transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
    overflow: hidden;
}

.side-menu.left {
    left: 0; right: auto;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
}

.side-menu.before-side {
    width: 280px;
}

.transparent-sidemenu .side-menu {
    background: rgba(0,0,0,.95);
}

.side-menu.side-menu-active,
.side-menu.before-side{
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    z-index: 999999;
}

.pul-menu .side-menu.side-menu-active {
    visibility: visible; opacity: 1;
}

.side-menu .navbar-brand {
    margin: 0 0 2.5rem 0;
}

/* Side overlay */
#close_side_menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    -webkit-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    -o-transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    transition: opacity 300ms cubic-bezier(0.895, 0.03, 0.685, 0.22);
    display: none;
    z-index: 1031;
    opacity: 0.4;
}

/* Side Close Btn */
.side-menu .btn-close {
    height: 33px;
    width: 33px;
    -webkit-box-shadow: 0 4px 4px -4px rgba(0,0,0,0.15);
    box-shadow: 0 4px 4px -4px rgba(0,0,0,0.15);
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    position: absolute;
    top: 48px;
    right: 34px;
    cursor: pointer;
}

.side-menu.before-side .btn-close{
    display: none;
}

.just-sidemenu #sidemenu_toggle {
    position: fixed;
    z-index: 999;
    /*background: #fff;*/
    right: 0;
    top: 16px;
    border-radius: 2px;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, .15);
    box-shadow: 0 0 20px rgba(0, 0, 0, .15);
}

.side-menu .btn-close::before, .side-menu .btn-close::after {
    position: absolute;
    left: 16px;
    content: ' ';
    height: 24px;
    width: 2px;
    background: #fff;
    top: 5px;
}

.side-menu .btn-close:before {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.side-menu .btn-close:after {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

/* Side Open Btn */
.sidemenu_btn {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    width: 45px;
    padding: 6px;
    margin-right: 1rem;
    position: absolute;
    right: 15px;
}

.sidemenu_btn.left {
    left: 15px;
    right: auto;
}

.sidemenu_btn > span {
    height: 2px;
    width: 100%;
    background: #ffffff;
    display: none;
}

.sidemenu_btn > span:nth-child(2) {
    margin: 4px 0;
}

.side-menu .inner-wrapper {
    padding: 3.5rem 5rem;
    height: 100%;
    position: relative;
    overflow-y: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.pul-menu.pushwrap .side-menu .inner-wrapper{
    padding: 3.5rem 2.5rem;
}

.side-menu .side-nav {
    margin: 100px 0;
    display: block;
}

.side-nav .navbar-nav .nav-item{
    display: block;
    margin: 10px 0; padding: 0 !important;
    opacity: 0;
    -webkit-transition: all 0.8s ease 500ms;
    -o-transition: all 0.8s ease 500ms;
    transition: all 0.8s ease 500ms;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
}

.side-nav .navbar-nav .nav-item:first-child {
    -webkit-transition-delay: .1s;
    -o-transition-delay: .1s;
    transition-delay: .1s;
}

.side-nav .navbar-nav .nav-item:nth-child(2){
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
}

.side-nav .navbar-nav .nav-item:nth-child(3) {
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}

.side-nav .navbar-nav .nav-item:nth-child(4) {
    -webkit-transition-delay: .4s;
    -o-transition-delay: .4s;
    transition-delay: .4s;
}

.side-nav .navbar-nav .nav-item:nth-child(5) {
    -webkit-transition-delay: .5s;
    -o-transition-delay: .5s;
    transition-delay: .5s;
}

.side-nav .navbar-nav .nav-item:nth-child(6) {
    -webkit-transition-delay: .6s;
    -o-transition-delay: .6s;
    transition-delay: .6s;
}

.side-nav .navbar-nav .nav-item:nth-child(7) {
    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s;
}
.side-nav .navbar-nav .nav-item:nth-child(8) {
    -webkit-transition-delay: .8s;
    -o-transition-delay: .8s;
    transition-delay: .8s;
}

.side-nav .navbar-nav .nav-item:nth-child(9) {
    -webkit-transition-delay: .9s;
    -o-transition-delay: .9s;
    transition-delay: .9s;
}

.side-menu.side-menu-active .side-nav .navbar-nav .nav-item {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
}

.side-nav .navbar-nav .nav-link {
    display: inline-table;
    color: #fff;
    padding: 2px 0 3px 0 !important;
    font-size: 1.5rem;
    font-weight: 300;
    line-height: normal;
    position: relative;
    border-radius: 0;
}

.side-nav .navbar-nav .nav-link::after{
    content: "";
    position: absolute;
    background: #fff;
    display: inline-block;
    width: 0;
    height: 3px;
    bottom: 0; left: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    -o-transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1);
}

.transparent-sidemenu .side-nav .navbar-nav .nav-link::after {
    display: none;
}

.side-nav .navbar-nav .nav-link:hover::after,
.side-nav .navbar-nav .nav-link:focus::after,
.side-nav .navbar-nav .nav-link.active::after {
    width: 100%;
}

.side-nav .navbar-nav .nav-link.active {
    background: transparent;
}

.transparent-sidemenu .side-nav .navbar-nav .nav-link:hover,
.transparent-sidemenu .side-nav .navbar-nav .nav-link:focus {
    margin-left: 5px;
}

.side-menu p{
    font-size: 15px;
    margin-top: .5rem;
    margin-bottom: 0;
    color: #FFFFFF;
}

/* Header Social Icons */
.navbar .social-icons li a {
    height: 28px; width: 28px;
    line-height: 28px;
    font-size: 14px;
}

.sidebar-nav .social-icons {
    position: absolute;
    bottom: 30px;
}

.side-menu .social-icons-simple li a:hover,
.side-menu .social-icons-simple li a:focus {
    color: #fff;
}

/*wrapper on opened sidebar*/
.wrapper {
    padding-left: 290px;
}

.wrapper .full-screen {
    width: 100% !important;
}

@media (max-width: 1366px) {
    .center-brand .sidemenu_btn {
        right: 0;
    }
}
@media (max-width: 1200px) {

    .side-menu .inner-wrapper {
        padding: 2rem 3.5rem;
    }

}
@media (min-width: 1025px) and (max-width: 1091px) {
    .navbar-nav.ml-auto {
        margin-right: 30px;
    }
    .sidemenu_btn {
        right: 0;
    }
}
@media (max-width: 1024px) {

    .boxed-nav .sidemenu_btn {
        display: none !important;
    }
    .center-brand .navbar-brand{
        width: 100px;
    }
    .navbar-nav .nav-link {
        font-size: 13px;
    }
}
@media (min-width: 810px) and (max-width: 1024px) {
    .sidemenu_btn {
        position: relative;
    }
}
@media (max-width: 992px) {
    .boxed-nav {
        background: #fff;
    }
    .center-brand .navbar-brand {
        position: relative;
        top: auto;
        margin: 16px 0;
        -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
        transform: translateY(0);
    }
    .navbar-nav .nav-item {
        margin: 5px 0;
    }
    .center-brand .navbar-nav .nav-link,
    .transparent-bg .navbar-nav .nav-link {
        color: #ffffff;
    }
    .center-brand.fixedmenu .navbar-nav .nav-link,
    .transparent-bg.fixedmenu .navbar-nav .nav-link {
        background: transparent;
    }
    .sidemenu_btn > span {
        display: block;
    }
    .side-menu {
        width: 50%;
    }
    .side-menu .inner-wrapper {
        padding: 2rem 2.5rem;
    }

    .side-nav .navbar-nav .nav-link {
        font-size: 2rem;
    }

}
@media (max-width: 768px) {
    .center-brand .navbar-brand{
        width: 160px;
        padding: 0 0px 0 20px;
    }
    .side-menu {
        width: 55%;
    }
    .side-nav .navbar-nav .nav-link {
        font-size: 1.5rem;
    }
    .side-menu .btn-close {
        top: 24px;
        right: 20px;
    }
}

/* ===================================
   Banner
====================================== */

.slider-area {
    height: 100vh;
    overflow: hidden; /* To hide extra content from the video */
    position: relative;
}

.slider-area video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

.slider-text {
    background-color: #ffffff;;
    color: #2b3033;
}
.landing-text {
    text-align: left;
}
.slider-area h1 {
    font-size: 4rem;
    line-height: 1.2;
}

.slider-area p {
    width: 70%;
    font-size: 30px;
}

.slider-area{
    align-items: center;
    display: flex;
    position: relative;
}

.circle-one {
    position: absolute;
    top: 34%;
    left: 56%;
}

.circle-two {
    top: 24%;
    left: 70%;
    position: absolute;
}

.circle-three {
    top: 33%;
    left: 83%;
    position: absolute;
}

.circle-four {
    top: 65%;
    left: 57%;
    position: absolute;
}

.circle-five {
    top: 58%;
    left: 76%;
    position: absolute;
}

.animated-circle {
    background-color: transparent;
    border: none;
    height: 40px;
    width: 40px;
    cursor: pointer;
    position: absolute;
}

.animated-circle:after {
    content: "";
    height: 25px;
    width: 25px;
    background-color: #f93134;
    border-radius: 50%;
    position: absolute;
    top: 5px;
    left: 10px;
    -webkit-box-shadow: 0 0 0 0 rgba(255, 101, 80, 0.4);
    box-shadow: 0 0 0 0 rgba(255, 101, 80, 0.4);
    -webkit-animation: ripple 2s infinite;
    animation: ripple 2s infinite;
}

@-webkit-keyframes ripple {
    70% {

        -webkit-box-shadow: 0 0 0 20px rgba(0, 159, 153, 0);
        box-shadow: 0 0 0 20px rgba(0, 159, 153, 0);
    }
    to {

        -webkit-box-shadow: 0 0 0 0 rgba(0, 159, 153, 0);
        box-shadow: 0 0 0 0 rgba(0, 159, 153, 0);
    }
}

@keyframes ripple {
    70% {

        -webkit-box-shadow: 0 0 0 20px rgba(0, 159, 153, 0);
        box-shadow: 0 0 0 20px rgba(0, 159, 153, 0);
    }
    to {

        -webkit-box-shadow: 0 0 0 0 rgba(0, 159, 153, 0);
        box-shadow: 0 0 0 0 rgba(0, 159, 153, 0);
    }
}

[data-tootik][data-tootik-conf*=invert]:after {
    background-color: #f93134;
    color: #ffffff;
}

[data-tootik][data-tootik-conf*=invert]:before {
    border-top-color: #f93134;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
}

[data-tootik][data-tootik-conf*=invert][data-tootik-conf*=bottom]:before {
    border-bottom-color: #f93134;
}

/* ===================================
        About
====================================== */
.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about-bg{
    background-color: black;
}
.why-bg {
    background : white;
}
.why-items {
    display: flex;
    padding-top: 60px;
}
.why-item {
    margin-left: 20px;
}
.why-item-box {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 385px;
    height: 325px;
    color: black;
    background-color: white;
    border-radius: 10px;
    padding: 20px;
    border: 2px solid #ec6d10;
}
.why-item-box h4 {
    margin-bottom: 20px;
    font-size: 18px;
}
.why-item-box p {
    font-size: 16px;
}
/* .why-item-box .news-title1 {
    margin-bottom: 50px !important;
} */
.why-item-box i {
    font-size: 40px;
    margin-bottom: 20px;
    margin-top: 10px;
    color: #ec6d10;
}

.main-heading h2 {
    font-family: 'Quicksand', sans-serif;
    font-size: 48px;
    font-weight: bold;
}

.main-heading h2 span {
    font-size: 60px;
}

.main-heading p {
    font-weight: normal;
    font-size: 20px;
    font-family: 'Quicksand' , sans-serif;
    width: 60%;
    margin: 0 auto;
}

.news-item{
    position: relative;
    overflow: hidden;
    text-align: left;
    cursor: pointer;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.news-item img{
    width: 100%;
    transition: all .5s ease 0.2s;
}

.image-holder-gallery{
    overflow: hidden;
}

.row-padding {
    padding-top: 100px;
}

.news-item:hover img{
    transform: scale(1.2);
    transition: all .5s ease 0.2s;
}

.news-item .news-text-box{
    padding: 20px 0;
    background-color: transparent;
}

.news-item .news-title{
    margin: 18px 0;
    font-size: 24px;
    font-weight: bold;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -ms-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
}

.news-item:hover .news-title {
    color: #ec6d10;
}

.news-item .btn-red:hover {
    color: #ec6d10;
}

.para {
    font-size: 18px;
    font-weight: 200;
}

.privacy-policy-container, .terms-container {
    padding-top: 200px;
    padding-bottom: 100px;
}

/* ===================================
     PRODUCTS
====================================== */

.all-products{
    background-color: black;
}

.owl-products{
    text-align: center;
}

.team-box{
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: 5px;
    background-color: transparent;
    -webkit-transition: 0.5s ease;
    -o-transition: 0.5s ease;
    transition: 0.5s ease;
    position: relative;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
}

.team-box .team-image{
    position: relative;
    overflow: hidden;
}

.team-box .team-text{
    text-align: center;
    font-size: 14px;
    padding: 2rem 0 0 0;
    opacity: 0;
    transition: .25ms;
}

.team-box .team-text .btn-medium {
    font-size: 16px;
    padding: 8px 36px;
    cursor: pointer;
}

.owl-products .owl-item.center .item{
    transform: scale(1.0);
}

.owl-products .owl-item.center .team-box .team-text h5 {
    font-size: 26px;
    color: #ffffff;
    font-weight: bold;
}

.owl-products .owl-item.center .item .team-text {
    opacity: 1;
    transition: .5s ease;
}

.all-products .circle{
    position: absolute;
    z-index: 1111;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.all-products .circle:hover i {
    color: #ec6d10;
    transition: .5s ease;
}

.all-products .circle i {
    font-size: 42px;
    z-index: 1111;
    color: #464646;
    opacity: .7;
    transition: .5s ease;
}

.all-products #team-circle-right {
    top: 60%;
    right: -8%;
    transform: translateY(-50%);
    transition: .5s ease;
}

.all-products #team-circle-left{
    top: 60%;
    left: -8%;
    transform: translateY(-50%);
    transition: .5s ease;
}

/* ===================================
   Counter
====================================== */

.counter-bg {
    background-image: url("../images/contact_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.counter-content span {
    color: #696969;
    font-size: 18px;
    font-weight: normal;
}

.counter-content h3 , .counter-content h3 span {
    font-family: Quicksand ,sans-serif;
    font-weight: bold;
    font-size: 36px;
    color: #ffffff;
}

.counter-content p {
    color: #696969;
    font-size: 14px;
    line-height: 1.8;
    font-weight: normal;
}

.parallax-box{
    font-family: 'Quicksand', sans-serif;
}

.parallax-box h2{
    font-size: 38px;
    margin-bottom: 10px;
    font-weight: bold;
}

.parallax-box h5{
    font-weight: normal;
    font-size: 16px;
    color: #ffffff;
}


/* ===================================
   Testimonials
====================================== */

.reviews{
    background-color: black;
}

.testimonial-img img {
    border-radius: 50%;
    display: inline-block;
}

.reviews .owl-carousel .owl-item img {
    width: 70px;
    height: 70px;
    margin: auto;
}

.icon-quotes{
    color: #2d2d2d;
    font-size: 24px;
    -webkit-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;
    cursor: pointer;
    line-height: 1.3;
    margin: 0 auto;
}

.paragraph {
    font-weight: 200;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
}

.testimonial-tittle h3 {
    font-size: 20px;
    font-weight: normal;
}

#wp-button {
    position: fixed;
    z-index: 9999;
    bottom: 20px;
    right: 25px;
  }
  #wp-button img {
    height: 55px;
    width: 55px;
    border-radius: 20%;
  }


/* ===================================
   Contact
====================================== */

.contact-sec{
    background-color: black;
}

.contact-form {
    padding-top: 50px;
}
.contact-sec .contact-form input{
    color: #ffffff;
    font-size: 16px;
    height: 40px;
    padding: 25px 0;
    background: transparent;
}

.contact-sec .contact-form .form-control{
    border: none;
    border-radius: 0;
    padding-left: 0;
    border-bottom: 1px solid rgba(255,255,255,0.3);
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #acacac!important;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #acacac!important;
}
::-ms-input-placeholder { /* Microsoft Edge */
    color: #acacac!important;
}

.contact-sec .contact-form .form-control:focus {
    box-shadow: none;
    border-bottom: 1px solid #ffffff;
}

.contact-sec .contact-form input::placeholder, .contact-sec .contact-form textarea::placeholder {
    color: #ffffff;
    font-size: 14px;
}

.contact-sec .contact-form textarea{
    padding-top: 20px;
    height: 150px;
    color: #ffffff;
    font-size: 16px;
    resize: none;
    background: transparent;
    margin-bottom: 40px;
}

.contact-sec .contact-form .purple-btn{
    margin-top: 15px;
}

.contact_btn {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
}

/*contact details*/
.contact-sec .contact-background{
    position: absolute;
    right: 0;
    top: -35px;
    z-index: -1;
}

.contact-sec .contact-details{
    font-family: "Quicksand", sans-serif;
    width: 90%;
    padding-top: 30%;
}

.contact-sec .contact-details .heading{
    margin-bottom: 35px;
    font-size: 30px;
}

.contact-sec .contact-details .text{
    color: #ffffff;
    font-size: 17px;
}

.contact-sec .contact-details ul{
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
}

.contact-sec .contact-details ul li{
    color: #ffffff;
    list-style: none;
    margin-bottom: 22px;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-family: 'Quicksand', sans-serif;
}

.contact-sec .contact-details ul li:first-child{
    width: 60%;
}

.contact-sec .contact-details ul li span{
    display: inline-block;
    padding-right: 30px;
}

.contact-sec .contact-details ul li span:last-child{
    padding-right: 0;
}

.contact-sec .contact-details ul li i{
    font-size: 30px;
    margin-right: 20px;
    margin-bottom: 8px;
    color: #ffffff;
}
.slider-social a {
    justify-content: center;
    height: 40px;
    line-height: 38px;
    width: 40px;
    position: relative;
    overflow: hidden;
    align-items: center;
    display: flex;
    text-align: center;
    border-radius: 50px;
    color: #ffffff;
    font-size: 16px;
    margin: 2px;
    border: 1px solid transparent;
    -webkit-transition: background-color .2s ease-in-out;
    -moz-transition: background-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out;
}

.policy-links {
    display: flex;
    flex-direction: column;
}

/* ===================================
     Stand Alone
====================================== */

.standalone {
    background-color: #202020;
}
.standalone-row {
    margin: 100px 200px 100px 200px;
}
.standalone-row:not(:last-child) {
    margin-bottom: 3rem;
}
.standalone-row .stand-img-des{
    padding-left: 30px;
    text-align: left;
}
.standalone-row:nth-child(2) .stand-img-des{
    padding-left: 0;
    padding-right: 30px;
}
.standalone-detail .sub-heading{
    margin-bottom: 10px;
}
.standalone-area .sub-heading{
    margin-bottom: 8px;
}
.standalone-detail .heading{
    margin-bottom: 20px;
    font-size: 56px;
    font-weight: 400;
    font-family: 'Quicksand', sans-serif;
}
.standalone-detail .para_text{
    font-family: 'Quicksand' , sans-serif;
    text-align: center;
    font-size: 18px;
    margin: 0 auto;
}
.standalone-detail{
    padding-bottom: 100px;
}
.standalone-area .heading-text{
    margin-bottom: 12px;
    font-family: 'Raleway', sans-serif;
    font-weight: 300;
    font-size: 45px;
}
.standalone-area .stand-img-des .para_text{
    font-family: 'Quicksand', sans-serif;
}
.page-title{
    position:relative;
    text-align:center;
    padding: 200px 0 150px 0;
    background:url("../images/standalone.jpg");
    background-size: cover;
    background-position: top center;
}
.page-title .auto-container{
    position:relative;
}
.page-title h2{
    position:relative;
    color:#ffffff;
    font-weight:600;
    line-height:1.2em;
    margin-bottom: 10px;
    font-size:3.5rem;
    text-transform:capitalize;
}
.page-breadcrumb{
    position:relative;
}
.page-breadcrumb li{
    position:relative;
    display:inline-block;
    margin-right:10px;
    padding-right:15px;
    color:#ffffff;
    font-size:14px;
    font-weight:400;
    text-transform:capitalize;
}
.page-breadcrumb li:after{
    position:absolute;
    content: "\f101";
    right:-5px;
    top:1px;
    color:#ffffff;
    font-size:14px;
    font-weight:900;
    font-family: "Font Awesome 5 Free";
}
.page-breadcrumb li:last-child::after{
    display: none;
}
.page-breadcrumb li:last-child{
    padding-right:0;
    margin-right:0;
}
.page-breadcrumb li a{
    color:#fff;
    font-weight:400;
    transition:all 0.3s ease;
    -moz-transition:all 0.3s ease;
    -webkit-transition:all 0.3s ease;
    -ms-transition:all 0.3s ease;
    -o-transition:all 0.3s ease;
}
.page-breadcrumb li a .icon{
    margin-right:4px;
    color:#33c9dd;
}
.page-breadcrumb li a:hover{
    color:#ffffff;
}

.footer {
    padding: 25px 0;
}

.footer-social ul{
    margin: 0;
    padding: 0;
}

.footer-social li{
    display: inline-block;
}

.footer-social a {
    display: block;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border-radius: 50px;
    font-size: 17px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
    -webkit-transition: background-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out;
}

.footer-social a i , .company-about {
    color: #ffffff;
}

.footer-social a:hover i {
    color: #ffffff;
}

.footer-social li:nth-last-child(1) a{
    margin-right: 0;
}

.company-about{
    font-size: 13px;
    font-family: 'Quicksand', sans-serif;
}

.main {
    background-color: black;
}

.stand-img-des {
    text-align: left;
}

/* ===================================
    PRELOADER
=================================== */

.loader {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 111111;
    background: #171717;
    overflow: hidden !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loader-container {
    width: 100px;
    height: 100px;
}
.loader-block {
    position: relative;
    box-sizing: border-box;
    float: left;
    margin: 0 10px 10px 0;
    width: 14px;
    height: 14px;
    border-radius: 3px;
    background: #808080; /* Grey color */
}

.loader-block:nth-child(4n+1) { animation: fire 1.5s ease .0s infinite; }
.loader-block:nth-child(4n+2) { animation: fire 1.5s ease .2s infinite; }
.loader-block:nth-child(4n+3) { animation: fire 1.5s ease .4s infinite; }
.loader-block:nth-child(4n+4) { animation: fire 1.5s ease .6s infinite; margin-right: 0; }

@keyframes fire {
    0% { opacity: 1; height: 14px; background: #ffffff; /* White color */ }
    50% { opacity: 0.5; height: 20px; background: #d3d3d3; /* Light grey color */ }
    100% { opacity: 1; height: 14px; background: #ffffff; /* White color */ }
}

@media screen and (max-width: 575px) {

}

/* ===================================
    MEDIA QUERIES
=================================== */

@media (min-width: 1800px) {
    .side-menu .side-nav {
        margin: 200px 0;
    }
    .circle-one {
        top: 37%;
        left: 56%;
    }
    .circle-two {
        top: 31%;
        left: 68%;
    }
    .circle-three {
        top: 37%;
        left: 78%;
    }
    .circle-four {
        top: 62%;
        left: 56%;
    }
    .circle-five {
        top: 55%;
        left: 73%;
    }
}

@media (max-width: 1200px) and (min-width: 992px) {

    .main-heading h2 {
        font-size: 34px;
    }
    .main-heading h2 span {
        font-size: 48px;
    }
    .main-heading p {
        font-size: 16px;
    }

    /* Banner */
    .slider-area h1 {
        font-size: 48px;
    }
    .circle-one {
        top: 41%;
        left: 58%;
    }
    .circle-two {
        top: 37%;
        left: 76%;
    }
    .circle-three {
        top: 40%;
        left: 92%;
    }
    .circle-four {
        top: 54%;
        left: 86%;
    }
    .circle-five {
        top: 58%;
        left: 60%;
    }

    /* Counter */
    .counter-content h3, .counter-content h3 span {
        font-size: 28px;
    }
    .parallax-box h5 {
        font-size: 14px;
    }
}

@media screen and (max-width: 991px) and (min-width: 768px){

    .main-heading h2 {
        font-size: 30px;
    }
    .main-heading h2 span {
        font-size: 42px;
    }
    .main-heading p {
        font-size: 16px;
    }
    .navbar .top-personal-info, .navbar .navbar-social  {
        display: none;
    }

    /* Banner */
    .slider-area h1 {
        font-size: 42px;
    }
    .slider-area p {
        font-size: 16px;
    }
    .circle-one {
        top: 40%;
        left: 57%;
    }
    .circle-two {
        top: 36%;
        left: 76%;
    }
    .circle-three {
        top: 40%;
        left: 92%;
    }
    .circle-four {
        top: 55%;
        left: 85%;
    }
    .circle-five {
        top: 59%;
        left: 59%;
    }

    /* About */
    .news-item .news-title {
        font-size: 20px;
    }
    .para {
        font-size: 15px;
    }

    /* Products */
    .owl-products .owl-item.center .team-box .team-text h5 {
        font-size: 22px;
    }
    .all-products .circle i {
        display: none;
    }

    /* Counter */
    .counter-content span {
        font-size: 14px;
    }
    .counter-content h3, .counter-content h3 span {
        font-size: 24px;
    }
    .parallax-box h5 {
        font-size: 14px;
    }

    /* Testimonials */
    .paragraph {
        font-size: 16px;
    }

    /* Contact */
    .contact-sec .heading {
        font-size: 42px;
    }
    .contact-sec .contact-details .text {
        text-align: left;
    }
    .contact-form {
        padding-top: 10%;
    }
    .contact-sec .contact-details {
        padding-top: 10%;
    }
    .contact-sec .contact-details .heading {
        text-align: left
    }
    .contact-sec .contact-details ul {
        margin-top: 0;
    }

    /* StandAlone */
    .standalone-detail {
        padding-bottom: 70px;
    }
    .standalone-area .gradient-text1{
        font-size: 30px;
    }
    .standalone-detail .heading{
        font-size: 30px;
    }
    .standalone-area .sub-heading {
        margin-top: 3rem;
    }
    .standalone-row .stand-img-des {
        padding-left: 0;
        padding-right: 0;
    }
    .standalone-row:nth-child(2) .stand-img-des {
        padding-left: 0;
        padding-right: 0;
    }
    .standalone-row:nth-child(2){
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .para_text {
        font-size: 18px;
    }
    .author-avatar {
        float: none;
        margin-bottom: 25px;
        overflow: visible;
    }
    .agency-author {
        padding: 30px;
        text-align: left;
    }
    .author-content {
        padding: 0;
    }
    .standalone {
        padding-bottom: 0;
    }
}

@media screen and (max-width:767px){
    body {
        text-align: center;
    }
    section {
        padding: 4rem 0;
    }
    .main-heading h2 {
        font-size: 26px;
    }
    .main-heading h2 span {
        font-size: 30px;
    }
    .main-heading p {
        font-size: 16px;
        width: 100%;
    }
    .navbar.sidebar-nav {
        width: 100%;
        height: auto;
        padding: 0 1rem;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
    .navbar .top-personal-info, .navbar .navbar-social  {
        display: none;
    }
    .wrapper {
        padding-left: 0;
    }

    /* Side Nav */
    .sidemenu_btn {
        margin-top: 4px;
        right: 0;
        position: absolute;
    }
    .side-menu {
        width: 300px;
    }
    nav .nav-link {
        font-size: 1.2rem!important;
    }
    .side-nav .navbar-nav .nav-item {
        margin: 5px 0;
    }
    .sidebar-nav .social-icons{
        display: none;
    }
    .center-brand {
        padding: 0;
        height: 70px;
        background-color: #000000;
    }
    .row-padding {
        padding-top: 50px;
    }

    /* Banner */
    .slider-area h1 {
        font-size: 30px;
    }
    .slider-area p {
        font-size: 18px;
        width: 100%;
    }
    .circle-one {
        top: 58%;
        left: 15%;
    }
    .circle-two {
        top: 52%;
        left: 56%;
    }
    .circle-three {
        top: 55%;
        left: 86%;
    }
    .circle-four {
        top: 77%;
        left: 74%;
    }
    .circle-five {
        top: 82%;
        left: 20%;
    }
    .btn.btn-medium {
        font-size: 14px;
        padding: 8px 44px;
        width: 97%;
    }

    /* About */
    .news-item .news-text-box {
        text-align: center;
    }
    .para {
        font-size: 15px;
        margin-bottom: 15px;
    }
    .about-bg span {
        display: inline!important;
    }
    .news-item {
        margin: 40px 0;
    }

    /* Products */
    .all-products .circle i {
        display: none;
    }
    .owl-products .owl-item.center .team-box .team-text h5 {
        font-size: 24px;
    }
    .team-box .team-text .btn-medium {
        font-size: 14px;
    }

    /* Counter */
    .counter-content span {
        font-size: 14px;
    }
    .counter-content h3, .counter-content h3 span {
        font-size: 24px;
    }

    /* Contact */
    .contact-sec .heading {
        font-size: 26px;
    }
    .contact-sec .contact-details {
        margin: 40px auto 0 auto;
    }
    .contact-sec .contact-details .text {
        font-size: 14px;
    }
    .contact-sec .contact-form {
        width: 100%;
        margin-left: 0;
    }
    .contact-sec .contact-details {
        width: 100%;
        margin-left: 0;
        margin-top: 80px;
    }
    .contact-sec .contact-details ul li:first-child {
        width: 100%;
    }
    .contact-sec .contact-details ul li {
        display: block;
        margin-bottom: 30px;
    }
    .contact-sec .contact-details ul li i{
        margin-right: 0;
        margin-bottom: 15px;
        display: block;
    }
    .contact-sec .contact-details ul {
        justify-content: center;
    }
    .contact-sec .contact-details {
        padding-top: 0;
    }

    /* Modal Window */
    .shoes-list {
        padding: 50px 0;
    }
    .morphic-title p {
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .modal-btn .btn.btn-medium {
        font-size: 12px;
    }
    .morphic-title h3 , .price-modal h1 {
        font-size: 30px;
    }
    .morphic-title p {
        font-size: 12px;
    }
    .carousel-indicators {
        margin-bottom: 10% !important;
    }
    .morphic-title h5 {
        font-size: 14px;
    }

    /* StandAlone */
    .standalone-detail {
        padding-bottom: 70px;
    }
    .standalone-area .gradient-text1{
        font-size: 30px;
    }
    .standalone-detail .heading{
        font-size: 30px;
    }
    .standalone-area .sub-heading {
        margin-top: 3rem;
    }
    .standalone-row .stand-img-des {
        padding-left: 0;
        padding-right: 0;
    }
    .standalone-row:nth-child(2) .stand-img-des {
        padding-left: 0;
        padding-right: 0;
    }
    .standalone-row:nth-child(2){
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .page-title h2{
        font-size: 2.5rem;
    }
    .standalone-area .heading-text {
        font-size: 30px;
    }
    .standalone-detail .para_text {
        font-size: 15px;
    }
    .para_text {
        font-size: 15px;
    }
    .why-items {
       flex-direction: column;
    }
    .why-item {
        margin-bottom: 30px;
        margin-left: 0;
    }
    .why-item-box {
        width: 350px;
    }
    .slider-area video {
        object-fit: contain;
        bottom: 128px;
        top: unset;
        left: unset;
    }
    .landing-text {
        margin-top: 330px;
    }
    
}