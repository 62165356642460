@import "./libraries/vendor/css/bundle.min.css";
@import "./libraries/vendor/css/LineIcons.min.css";
@import "./libraries/vendor/css/jquery.fancybox.min.css";
/*@import "./libraries/vendor/css/owl.carousel.min.css";*/
@import "./libraries/vendor/css/wow.css";
@import "./libraries/vendor/css/slick-theme.css";
@import "./libraries/vendor/css/slick.css";
@import "./libraries/vendor/css/line-awesome.min.css";
@import "./libraries/specific/css/tootik.min.css";
@import "./libraries/specific/css/line-awesome.min.css";
@import "./libraries/specific/css/model-window.css";
@import "./libraries/specific/css/style.css";
@import "./libraries/specific/css/style2.css";
@import "./libraries/specific/css/select2.min.css";
@import "./libraries/specific/css/select2.min.css";

.product-image-carousel {
    position: relative;
    width: 350px;
    img {
        margin-bottom: 20px;
    }
    text-align: center;
    margin-bottom: 100px;
}

.splide__slide img {
    width: 100%;
    transition: transform 0.3s ease;
}

/* Scale up the active slide */
.splide__slide.is-active img {
    transform: scale(1.1); /* Adjust scale value as needed */
}

/* Optional: Style for the slide content to make it visually centered */
.product {
    text-align: center;
}

.products-carousel {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr; /* Middle column is larger */
    gap: 10px; /* Adjust the gap between grid items */
}

.grid-item {
    background-color: #f0f0f0; /* Light grey background for visibility */
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .grid-container {
        grid-template-columns: 1fr; /* Stack items vertically on smaller screens */
    }
}
