/*************************Morphing Model Window***************************/

/* ===================================
    Modal window css
====================================== */

.modal-window{
    position: fixed;
    top:0;
    left:0;
    right:0;
    background-color: #5856569c;
    z-index: 11111;
    display: none;
    overflow-y: scroll;
    height: 100vh;
    transition: .5s ease;
}
.morphic-body {
    padding: 5% 0;
}
.modal-view{
    display: block;
}
.modal-body{
    max-width: 1200px;
    margin: 25px auto;
    background-color: #171717;
    height: auto;
    opacity: 1;
    display: block;
    animation: .8s fadeIn;
}
.morphic-title{
    padding: 10px;
    padding-left: 20px;
    font-family: 'Oswald', sans-serif;
    color: #FFFFFF;
}
.morphic-title h3{
    font-family: 'Montserrat', sans-serif;
    font-size: 42px;
    font-weight:bold;
    color: #ffffff;
    text-transform: capitalize;

}
.morphic-title h5{
    font-family:'Montserrat', sans-serif ;
    font-size: 18px;
    font-weight: 600;
    color: #ffffff;
    margin: 0;
}
.morphic-title p{
    font-family:'Roboto', sans-serif ;
    font-size: 18px;
    font-weight: 300;
    color: #ffffff;
    margin-top: 50px;
    margin-bottom: 50px;
    line-height: 1.7;
}
.left-arrow-sec a,.right-arrow-sec a{
    display: inline-block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: solid 1px #ffffff;
    text-align: center;
    margin-top: 5px;
    transition: .8s ease;
    cursor: pointer;
}
.left-arrow-sec a i,.right-arrow-sec a i{
    color: #ffffff;
    font-size: 20px;
    margin-top: 9px;
    transition: .8s ease;
}
.left-arrow-sec a:hover,.right-arrow-sec a:hover{
    border: solid 1px #c42629;
    background-color: #c42629;
    animation: 1s tada;
}
.left-arrow-sec a:hover i,.right-arrow-sec a:hover i{
    color:#ffffff;
}
.morphic-body img{
    height: 100%;
    width: 100%;
}
.morphic-detail h4{
    font-size: 18px;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    color: #FFFFFF;
    margin-bottom: 3%;
    margin-top: 2%;
}
.morphic-detail p{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #FFFFFF;
    font-weight: 300;
}
.morphic-info{
    margin-top: 5%;
    margin-bottom: 5%;
}
.morphic-info .info{
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #FFFFFF;
}
.morphic-info span{
    color: #FFFFFF;
    margin-right: 4px;
}

.close-modal{
    z-index: 111111;
    position: absolute;
    right: 30px;
    top: 20px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}
.close-modal i{
    display: inline-block;
    height: 2px;
    width: 25px;
    background-color: #ffffff;
    margin: 0;
    padding: 0;
    transition: .5s ease;
}
.close-modal:hover i {
    background-color: #ed2d34;
}
.close-modal i:first-child{
    transform: rotate(45deg);
    margin-left: 4px;
}
.close-modal i:last-child{
    transform: rotate(-45deg);
    margin-left: -24px;
}

.carousel-indicators {
    position: relative;
    margin-bottom: 2% !important;
    margin-top: 6% !important;
    margin-left: 0;
    margin-right: 0;
}
.carousel-indicators li {
    height: 135px;
    width: 30%;
    border-radius: 50%;
    margin-right: 15px;
    background: transparent;
}

.left-arrow-sec {
    display: flex !important;
    justify-content: space-around;
    padding: 0 8px;
}

.right-arrow-sec {
    display: flex !important;
    justify-content: space-around;
    padding: 0 8px;
}

.btn {-webkit-appearance: initial; overflow: hidden;position: -webkit-sticky;position: sticky; z-index: 2; display:inline-block; font-size: 17px; border:2px solid transparent; letter-spacing: .5px; line-height: inherit; border-radius: 0; text-transform:capitalize; width: auto;font-family: 'Quicksand', sans-serif; font-weight: 500; -webkit-transition: all .5s ease;-o-transition: all .5s ease !important;transition: all .5s ease !important;}
/*Slightly desaturated orange btn*/
.black-btn{font-size: 12px;font-family: 'Quicksand', sans-serif;padding: 10px 25px;color:#FFFFFF;font-weight: 600;background-color:transparent;border:2px solid #FFFFFF;border-radius: 0px;-webkit-transition: .8s ease !important;-o-transition: .8s ease !important;transition: .8s ease !important;}
.black-btn:hover{color: black; ;background-color:#FFFFFF !important; }


/*************************Morphing Model Window end***************************/

/*Model Quantity*/

.input_plus_mins .qty .count {
    color: #ffffff;
    background: transparent;
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    font-weight: 300;
    /*line-height: 30px;*/
    padding: 0 2px ;
    min-width: 35px;
    text-align: center;
    margin-bottom: 0;
    font-family: Roboto, sans-serif;
}
.input_plus_mins .qty .plus {
    cursor: pointer;
    display: inline-block;
    vertical-align: center;
    color: #ffffff;
    width: 26px;
    height: 26px;
    text-align: center;
    position: relative;
    background-color: transparent !important;
}

.input_plus_mins .qty .plus i{
    position: absolute;
    top: 48%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 10px;
}

.input_plus_mins .qty .minus {
    cursor: pointer;
    display: inline-block;
    vertical-align: center;
    color: #ffffff;
    width: 26px;
    height: 26px;
    text-align: center;
    background-clip: padding-box;
    position: relative;
    background-color: transparent !important;
}
.input_plus_mins .qty .minus i {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    font-size: 10px;
}

/*Prevent text selection*/
.input_plus_mins span{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}
.input_plus_mins input{
    border: 0;
    width: 2%;
}
.input_plus_mins nput::-webkit-outer-spin-button,
.input_plus_mins input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.input_plus_mins input:disabled{
    background-color:white;
}

/*Model Size Chooser*/

.size__item {
    display: inline-block;
    cursor: pointer;
}
.size__item a {
    font-family:'Roboto', sans-serif ;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    opacity: .5;
    display: inline;
}
.size__item a {
    margin-right: 25px;
}
.price-modal h1 {
    font-family:'Montserrat', sans-serif ;
    font-size: 48px;
    font-weight: bold;
    color: #ffffff;
    margin: 25px 0;
}
.modal-btn .btn.btn-medium{
    font-family:'Montserrat', sans-serif !important;
    font-size: 18px;
    font-weight: bold;
}
.shoes-list {
    padding-top: 100px;
}

@media screen and (max-width:767px){
    .modal-body {
        margin: 7px auto;
    }
    .carousel-indicators li {
        height: 100px;
    }
    .close-modal {
        right: 20px;
    top: 0px;
    }
}