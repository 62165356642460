/* ===================================
    About
====================================== */

/*
    Theme Name: MegaOne
    Theme URI:
    Author: Themes Industry
    Author URI:
    Description: One Page Parallax Template
    Tags: one page, multipurpose, parallax, creative, html5

 */

/* ===================================
    Table of Content
====================================== */


/*  - Fonts
    - General
    - Fonts
    - Social Icon Hover
    - Header And Navigation
    - Slider
    - About
    - Team
    - Portfolio
    - Price
    - Parallax
    - Testimonial
    - Client
    - Blog
    - Contact
    - Map
    - Footer
    - Loader
    - Media Queries   */


/* ===================================
    Google font
====================================== */
@import url('https://fonts.googleapis.com/css2?family=Quicksand&family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grand+Hotel&display=swap');


/* ===================================
    Scrollbar
====================================== */
html{
    scroll-behavior: smooth;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    width: 12px;
    background:transparent;
    border-left: 0 solid transparent;
    border-right: 0 solid transparent;
}
::-webkit-scrollbar-thumb {
    background: #ec6d10;
    width: 0;
    height: 25%;
    transition: .5s ease;
}

.minicart ::-webkit-scrollbar {
    width: 5px;
}
.minicart ::-webkit-scrollbar-track {
    width: 12px;
    background:white;
    border-left: 0 solid white;
    border-right: 0 solid white;
}
.minicart ::-webkit-scrollbar-thumb {
    background: #ec6d10;
    width: 0;
    height: 25%;
    transition: .5s ease;
}
/* ===================================
    General
====================================== */

a, *::before, *::after, img, span, input, button, .navbar, .fixedmenu, .tp-bullet, .owl-dot, .owl-prev, .owl-next {
    transition: ease-in-out 0.2s;
    -webkit-transition: ease-in-out 0.2s;
    -moz-transition: ease-in-out 0.2s;
    -ms-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
}
section,.section-padding {
    position: relative;
    overflow: hidden;
}
a {
    color:inherit;
    text-decoration: none;
}
.btn.focus, .btn:focus{
    -webkit-box-shadow: none;
    box-shadow: none;
}
p:last-child{
    margin-bottom: 0 !important;
}
.fa, .far, .fas{
    display: inline;
}
a:active, a:hover{
    color: #ec6d10;}
.hidden{
    display: none;
}
ul{
    margin-bottom: 0;
}

/* transition none */
.no-transition *{transition-timing-function:initial; -moz-transition-timing-function:initial; -webkit-transition-timing-function:initial; -o-transition-timing-function:initial; -ms-transition-timing-function:initial; transition-duration: 0s; -moz-transition-duration: 0s; -webkit-transition-duration: 0s; -o-transition-duration: 0s; -ms-transition-duration: 0s;}

/* overlay */
.bg-overlay{position: absolute;top: 0;left: 0;width: 100%;height: 100%;}

/* opacity */
.opacity-3{opacity: .3;}
.opacity-4{opacity: .4;}
.opacity-5{opacity: .5;}
.opacity-6{opacity: .6;}
.opacity-7{opacity: .7;}
.opacity-8{opacity: .8;}
.opacity-9{opacity: .9;}

/* button */
.btn {-webkit-appearance: initial; overflow: hidden;position: -webkit-sticky;position: sticky; z-index: 2; display:inline-block; font-size: 17px; border:2px solid transparent; letter-spacing: .5px; line-height: inherit; border-radius: 0; text-transform:capitalize; width: auto;font-family: 'Quicksand', sans-serif; font-weight: 500; -webkit-transition: all .5s ease;-o-transition: all .5s ease !important;transition: all .5s ease !important;}
/* button size */

/* background color */
.bg-dark{background-color: #202020;}
.bg-purple {background-color: #350d82;}


/* text-color */
.red-color{color: #ec6d10;}
.purple-color {color: #350d82;}

/*Max Width*/
.parallax-disable{
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center !important;
}
.position-relative{
    position: relative;
}

/*icon text bover */
.facebook-text-hvr:hover{color:#4267B2 !important;}
.twitter-text-hvr:hover{color:#1DA1F2 !important;}
.instagram-text-hvr:hover{color:#C32AA3 !important;}
.linkedin-text-hvr:hover{color:#0077B5 !important;}
.pinterest-text-hvr:hover{color:#BD081C !important;}
.google-text-hvr:hover{color:#DB4437 !important;}


/* ===================================
    menu-line
====================================== */
.menu-line {
    position: absolute;
    top: -20px;
    font-size: 18px;
    color: #ffffff;
    left: 0;
    display: block;
    text-align: center;
    width: 150px;
    height: 30px;
    pointer-events: none;
    -webkit-transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    -webkit-transition-timing-function: cubic-bezier(1, 0.01, 0, 1);
    -webkit-transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22);
    transition-timing-function: cubic-bezier(1, 0.01, 0, 1.22);
}
/* ===================================
    Cursor
====================================== */

.magic{
    display: none !important;
}
.aimated-cursor {
    position: absolute;
    left: 0;
    display: none;
    top: 0;
    width: 30px;
    height: 30px;
    pointer-events: none;
    z-index: 10000;
    -webkit-transition: opacity 0.2s ease-in-out 0.5s;
    -o-transition: opacity 0.2s ease-in-out 0.5s;
    transition: opacity 0.2s ease-in-out 0.5s;
}

.cursor-light:hover ~ .aimated-cursor{
    display: inline-block;
    z-index: 9999999;
}
.cursor {
    position: fixed;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border: 2px solid rgb(255, 255, 255);
    /*border: 2px solid rgb(0, 0, 0);*/
    border-radius: 50%;
    pointer-events: none;
    opacity: 1;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    z-index: 999999999;
}



/* ===================================
      Custom css
  ==================================== */

.padding-top{
    padding-top: 85px;
}
.padding-top2{
    padding-top: 250px;
}
.padding-top-half{
    padding-top: 60px;
}
.padding-bottom{
    padding-bottom: 120px;
}
.border-row{
    border: solid;
    padding: 20px 0;
}
.sub-heading{
    background:#ec6d10;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Quicksand', sans-serif;
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 30px;
}
.bg-grey{
    background-color: #2d2d2d;
}
.bg-black{
    background-color: #191919;
}
.text-trans{
    background:#ec6d10;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.heading{
    color: #ffffff;
    font-family: 'Quicksand', cursive;
    font-size: 72px;
    font-weight: 400;
    margin-bottom: 50px;
}
.text{
    font-family: 'Quicksand', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #ffffff;
    opacity: .7;
}
.color-dark{
    color: #797979;
}
.color-white{
    color: #ffffff;
}

.white-trans-btn{
    border: solid 1px #FFFFFF;
    color: #FFFFFF;
    font-weight: 400;
    padding: 8px 35px;
    background-color: transparent;
    transition: .5s ease;
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
}
.white-trans-btn:hover{
    background-color: #FFFFFF;
    color: #202020;
}
.main-btn{
    border: solid 1px #fc2547;
    color: #FFFFFF !important;
    font-weight: 700;
    padding: 10px 35px;
    font-size: 14px;
    font-family: 'Quicksand', sans-serif;
    text-transform: uppercase;
    /*letter-spacing: 0.1em;*/
    text-align: center;
    overflow: hidden;
    transition: .8s ease;
    text-decoration: none;
    background-image:linear-gradient(to right,#fc2547 0%, #ff5a00 50%, #fc2547 100%);
    background-size: 200% auto;
}
.main-btn:hover{
    background-position: right center;
}


.anim-btn {
    border: 1px solid #FFFFFF;
    border-radius: 0.5em;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background-color: transparent;
    color: #FFFFFF;
    font-size: 13px;
    padding: 10px 30px 10px 30px;
    transition: .8s ease;
    font-family: 'Quicksand', sans-serif;
    font-weight: 500;
    text-decoration: none;
}
.anim-btn:hover{
    border:solid 1px #FFFFFF;
    color: #202020;
}
.anim-btn span {
    position: absolute;
    width: 25%;
    height: 100%;
    background-color: #FFFFFF;
    transform: translateY(150%);
    border-radius: 50%;
    transition: 0.5s;
    z-index: -1;
}

.anim-btn:hover span {
    transform: translateY(0) scale(3.1);
}

.anim-btn span:nth-child(1){
    left: calc((1 - 1) * 25%);
    transition-delay: calc((1 - 1) * 0.1s);
}

.anim-btn span:nth-child(2){
    left: calc((2 - 1) * 25%);
    transition-delay: calc((2 - 1) * 0.1s);
}

.anim-btn span:nth-child(3){
    left: calc((3 - 1) * 25%);
    transition-delay: calc((3 - 1) * 0.1s);
}

.anim-btn span:nth-child(4) {
    left: calc((4 - 1) * 25%);
    transition-delay: calc((4 - 1) * 0.1s);
}
.anim-btn span:nth-child(5){
    left: calc((5 - 1) * 25%);
    transition-delay: calc((5 - 1) * 0.1s);
}

/* ===================================
           main  Banner css
====================================== */
.main-banner{
    background-color: #202020;
    height: 100vh;
}
/*slider form*/
.slider-form{
    position: absolute;
    z-index: 11;
    top: 74%;
    transform: translateY(50%);
    left: 0;
    right: 0;
}
.slider-form .contact-form{
    background-color: #2d2d2d;
    padding: 10px;
    width: 80%;
    margin: auto;
}
.slider-form .contact-form .form-group{
    display: flex;
    align-items: center;
    margin-bottom: 0;
    width: 100%;
}
.slider-form .contact-form label{
    margin-bottom: 0;
    color: #3d3d3d7d;
    position: relative;
    left: 13px;
    z-index: 11;
    font-size: 17px;
    display: flex;
}
.slider-form .contact-form label i{
    font-size: 26px;
    color: #595959;
}
.slider-form .contact-form input{
    color: #595959;
    font-size: 16px;
    height: 50px;
    padding: 25px;
}
.slider-form .contact-form .form-control{
    border-radius: 0;
    border:solid 2px transparent;
    margin-bottom: 0;
    border-right: solid 2px #eceef2;;
    background-color: transparent;
}
.slider-form .contact-form .form-control:last-child{
    border-right: none;
}
.slider-form .contact-form input::placeholder{
    color: #595959;
    font-size: 16px;
}
select::placeholder { color: #ffffff; }
.slider-form .main-btn{
    padding: 15px 35px;
}
.form-control:focus {
    border-color: #80bdff00;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
/* ===================================
          gallery sec
====================================== */
.gallery-sec{
    background-color: black;
}
.gallery-sec .heading-area{
    width: 80%;
}
.gallery-sec .mini-services .mini-service-card{
    text-align: center;
    cursor: pointer;
}
.gallery-sec .mini-services .mini-service-card i{
    background:#ec6d10;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 40px;
    margin-bottom:10px;
    transition: .5s ease;
}
.gallery-sec .mini-services .mini-service-card:hover i{
    transform: translateY(-10px);
}
.gallery-sec .mini-services .mini-service-card .number{
    color: #ffffff;
    opacity: .7;
    margin-bottom: 5px;
    font-weight: 400;
}

/* ===================================
    portfolio area inner gallery sec
====================================== */
.portfolio-area{}
.portfolio-area .portfolio-item{
    cursor: pointer;
    margin-bottom: 30px;
}
.portfolio-area .portfolio-item .portfolio-inner-content{
    background-color: #0d0d0d;
    border-radius: 10px;
}
.portfolio-area .portfolio-item .portfolio-inner-content .item-img-holder{
    overflow: hidden;
}
.portfolio-area .portfolio-item .portfolio-inner-content .item-img-holder img{
    width: 100%;
    transition: .5s ease;
    border-radius: 10px 10px 0 0;
}
.portfolio-area .portfolio-item:hover .portfolio-inner-content .item-img-holder img{
    transform: scale(1.05);
}
.portfolio-area .portfolio-item .portfolio-inner-content .item-img-holder .item-badge{
    background: #ec6d10;
    font-size: 16px;
    line-height: 1;
    color: #ffffff;
    height: 80px;
    width: 80px;
    position: absolute;
    right: -19px;
    top: -34px;
    display: block;
    text-align: center;
    padding-top: 39px;
    font-weight: 500;
}
.portfolio-area .portfolio-item .portfolio-inner-content .item-img-holder .item-badge span{
    display: block;
    font-weight: 300;
    font-size: 12px;
}
.portfolio-area .portfolio-item .portfolio-inner-content .item-detail-area{
    padding: 20px;
}
.portfolio-area .portfolio-item .portfolio-inner-content .item-detail-area .item-name{
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    transition: .5s ease;
}
.portfolio-area .portfolio-item .portfolio-inner-content .item-detail-area .item-reviews{
    padding-left: 0;
}
.portfolio-area .portfolio-item .portfolio-inner-content .item-detail-area .item-reviews li{
    list-style: none;
    display: inline-block;
    margin-left: -1px;
}
.portfolio-area .portfolio-item .portfolio-inner-content .item-detail-area .item-reviews li i{
    color: #ffc13c;
    font-size: 17px;
}
.portfolio-area .portfolio-item .portfolio-inner-content .item-detail-area .text{
    margin-top: 2px;

}
.portfolio-area .portfolio-item:hover .portfolio-inner-content .item-detail-area .item-name{
    animation: 1s hoverall;
    color: #fe461a;
    /*background:#ec6d10;*/
    /*-webkit-background-clip: text;*/
    /*-webkit-text-fill-color: transparent;*/
}

.gallery-sec .main-btn{
    margin-top: 60px;
}

/* ===================================
          Testimonials sec
====================================== */
.testimonial{
   background-color: black;
}
.testimonial .bg-overlay{
    background-color: black;
}
.feedback-slides {
    position: relative;
}

.feedback-slides .client-feedback {
    position: relative;
}

.feedback-slides .client-feedback .single-feedback {
    text-align: center;
    position: relative;
    padding-top: 20px;
}

.feedback-slides .client-feedback .single-feedback p {
    position: relative;
    max-width: 650px;
    line-height: 1.5;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-weight: normal;
}

.feedback-slides .client-thumbnails {
    position: relative;
    max-width: 450px;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
}

.feedback-slides .client-thumbnails .item .img-fill {
    cursor: pointer;
    position: relative;
    text-align: center;
}

.feedback-slides .client-thumbnails .item .img-fill img {
    opacity: .8;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    display: inline-block;
    position: relative;
    border-radius: 50%;
    -webkit-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
}

.feedback-slides .client-thumbnails .item .title {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    margin: 20px -100px 0;
    text-align: center;
}

.feedback-slides .client-feedback .single-feedback h3{
    margin-bottom: 0;
    font-size: 25px;
    font-weight: 500;
    text-transform: capitalize;
    color:#ec6d10 !important;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.feedback-slides .client-thumbnails .item .title .user-designation{
    display: block;
    color: #FFFFFF;
    font-size: 14px;
    margin-top: 5px;
}

.feedback-slides .client-thumbnails .slick-slide.slick-center .title {
    opacity: 1;
    visibility: visible;
}

.feedback-slides .client-thumbnails .slick-slide.slick-center .img-fill img {
    border-color: #002e5b;
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
}


.testimonial .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border:solid 2px #fff;
    outline: none;
}

.testimonial .slick-dots li.slick-active button{
    background: #ec6d10;
}

.testimonial .slick-dots li {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 8px;
    padding: 0;
    cursor: pointer;
}

.testimonial .slick-dots {
    position: absolute;
    bottom: -40px;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.categories-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.feedback-slides:hover .next-arrow, .feedback-slides:hover .prev-arrow {
    opacity: 1;
    visibility: visible;
}

.feedback-slides .slick-list {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.single-feedback-item {
    position: relative;
    margin-left: 25px;
    margin-top: 25px;
    margin-bottom: 8px;
}

.client-feedback{
    text-align: center;
}


.testimonial .slick-dots li.slick-active button:before {
    opacity: 0;
    color: black;
}

.testimonial .slick-dots li button::before {
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "â€¢";
    text-align: center;
    opacity: 0;
    color: black;
    -webkit-font-smoothing: antialiased;
}

.testimonialb .slick-dots li button:hover:before,.testimonial .slick-dots li button:focus:before {
    opacity: 0;
}


/* ===================================
            App Landing sec
====================================== */
.app-sec{
    background-color: #161616;
}
.app-sec .heading-area{
    width: 50%;
    margin: auto;
}
.app-sec .heading-area .sub-heading{
    margin-bottom: 50px;
}
.app-sec .apps-details {
    width: 65%;
    margin: auto;
}
.app-sec .apps-details .app-l{
    cursor: pointer;
}
.app-sec .apps-details .app-l .img-holder{
    width: 200px;
    height: 200px;
    margin: auto;
    margin-bottom: 10px;
    transition: .8s ease;
}
.app-sec .apps-details .app-l:hover .img-holder{
    transform: translateY(-10px);
}
.app-sec .apps-details .app-l .img-holder img{
    height: 100%;
    width: 100%;
}
.app-sec .apps-details .app-l .app-loc{
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 300;
    margin-top: 50px;
    opacity: .7;
    transition: .8s ease;
}
.app-sec .apps-details .app-l:hover .app-loc{
    color: #fe461a;
    opacity: 1;
}

/* ===================================
            About Sec
====================================== */

.about-sec{
    background-color: #1d1d1d;
}
.about-sec .heading-area{
    width: 80%;
}
.about-sec .detail-area .text{
    font-size: 15px;
}
.features {
    width: 90%;
    margin: auto;
}
.features .feature-card {
    cursor: pointer;
}
.features .feature-card i{
    color: #FFFFFF;
    font-size: 90px;
    display: inline-block;
}
.features .feature-card:hover i,.features .feature-card.active i{
    background:#ec6d10;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: .8s pulse infinite;
}
.features .feature-card .text{
    font-size: 20px;
    opacity: 1;
    width: 72%;
    margin: auto;
    margin-top: 30px;
}
/* ===================================
            Footer sec
====================================== */
.foot-effect{
    margin-bottom: 245px;
}
.footer-style-1{
    padding: 5rem 0;
    background-color: #171717;
    position: fixed;
    z-index: -1;
    bottom: 0;
    width: 100%;
}

.footer-social ul{
    margin: 0;
    padding: 0;
}

.footer-social li{
    display: inline-block;
}

.footer-social a {
    position: relative;
    display: block;
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border-radius: 50px;
    font-size: 17px;
    margin: 0 10px 0 0;
    border: 1px solid transparent;
    -webkit-transition: background-color .2s ease-in-out;
    -o-transition: background-color .2s ease-in-out;
    transition: background-color .2s ease-in-out;
    color: #FFFFFF;
    visibility: visible !important;

}
.footer-social a i{
    position: relative;
    z-index: 11;
}

.footer-social li:nth-last-child(1) a{
    margin-right: 0;
}

.footer-social li a span{
    position: absolute;
    left: 100%;
    right: 100%;
    bottom: 100%;
    top: 100%;
    background:#ec6d10;
    z-index: 1;
    border-radius: 50%;
    transition: .5s ease;
}
.footer-social li a:hover span{
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
}

.company-about{
    font-size: 14px;
    color: #FFFFFF;
}
/* ===================================
   Scroll Top
====================================== */

.scroll-top-arrow, .scroll-top-arrow:focus {
    font-size: 23px;
    line-height: 47px;
    color: #fff;
    display: none;
    height: 45px;
    width: 45px;
    cursor: pointer;
    padding: 0;
    position: fixed;
    bottom: 100px;
    right: 20px;
    text-align: center;
    text-decoration: none;
    z-index: 80;
    border-radius: 50px;
    -webkit-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background: #ec6d10;
    background-size: 200% auto;
}
.scroll-top-arrow:hover{
    background-position: right center;
}



/* ===================================
    Background images
====================================== */

[class*=bg-img]{
    position: relative;
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    overflow: hidden;
}


/* ===================================
        Preloader
   ====================================== */

.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: #202020;
    z-index: 999;
}
.center {
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.mo-fire {
  width: 200px;
  height: auto;
  position: absolute;
  left:50%;
  margin-left: -100px;
  top: 300px;
}
.mo-fire svg {
  width: 100%;
  height: auto;
  position: relative
}
.flame {
  animation-name: flamefly;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  opacity: 0;
  transform-origin: 50% 50% 0;
}
.flame.one {
  animation-delay: 1s;
  animation-duration: 3s;
}
.flame3.two{
  animation-duration: 5s;
  animation-delay: 1s;
}

.flame-main {
  animation-name: flameWobble;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.flame-main.one {
  animation-duration: 4s;
  animation-delay: 1s;
}
.flame-main.two {
  animation-duration: 3s;
  animation-delay: 2s;
}
.flame-main.three {
  animation-duration: 2.1s;
  animation-delay: 3s;
}
.flame-main.four {
  animation-duration: 3.2s;
  animation-delay: 4s;
}
.flame-main.five {
  animation-duration: 2.5s;
  animation-delay: 5s;
}
@keyframes flameWobble {
  50% {
    transform: scale(1,1.2) translate(0, -30px) rotate(-2deg);
  }
}
@keyframes flamefly {
  0%{
    transform: translate(0) rotate(180deg);
  }
   50% {
     opacity: 1;
  }
  100% {
    transform: translate(-20px, -100px) rotate(180deg);
    opacity: 0;
  }
}

/*effect*/




/* ===================================
       mini shopping cart
====================================== */

.header-shop-cart .minicart {
    background: #191919;
    opacity: 0;
    padding: 35px 25px 25px 25px;
    position: absolute;
    right: -15px;
    top: 70px;
    transition: all 0.5s ease 0s;
    width: 360px;
    z-index: 9;
    box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.3);
    visibility: hidden;
    transform: translateY(10px);
}
.header-shop-cart .minicart::before {
    position: absolute;
    content: '';
    left: 0;
    top: -25px;
    width: 100%;
    height: 45px;
    display: block;
}
.header-shop-cart:hover .minicart {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    z-index: 9;
}
.header-shop-cart .minicart .minicart-content {
    height: 218px;
    overflow-y: scroll;
}
.header-shop-cart .minicart .minicart-content>div{
    margin-bottom: 20px;
}
.header-shop-cart .minicart > div ,.header-shop-cart ul > li{
    display: block;
    margin-bottom: 22px;
    margin-left: 0;
    overflow: hidden;
    padding: 0;
    margin-right: 0;
}
.header-shop-cart .minicart .cart-img {
    float: left;
    padding-left: 0;
}
ul.minicart .cart-img img {
    width: 100%;
}
.header-shop-cart .minicart .cart-content {
    /*float: left;*/
    padding-left: 0;
    text-align: left;
}

.minicart .cart-content h4 {
    font-size: 15px;
    background: none;
    font-weight: 500;
    line-height: 1.4;
}

.minicart .minicart-content>div:hover h4 a {
    background:#ec6d10;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.minicart .cart-content h4 a {
    color: #FFFFFF;
    font-size: 16px;
}
.minicart .cart-price span {
    color: #FFFFFF;
    opacity: .6;
    font-size: 13px;
    font-weight: 500;
    margin-left: 6px;
}
.minicart .cart-price .new {
    font-size: 14px;
    color: #FFFFFF;
    margin-left: 0;
}
.header-shop-cart .minicart .text {
    display: inline-block;
    float: left;
}
.header-shop-cart .minicart .del-icon {
    margin-top: 6px;
    float: right;
}
.minicart .del-icon > a {
    font-size: 18px;
    color: #FFFFFF;
}
.total-price {
    border-top: 1px solid #FFFFFF;
    overflow: hidden;
    padding-top: 25px;
    margin-top: 10px;
    font-size: 30px;
    text-align: center;
}
.total-price span {
    color: #FFFFFF;
    font-weight: 500;
}
.header-shop-cart .minicart > li:last-child {
    margin-bottom: 0;
}
.header-shop-cart .minicart .checkout-link a {
    color: #fff;
    display: block;
    padding: 10px 30px;
    text-align: center;
    font-size: 14px;
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.header-shop-cart .minicart .checkout-link a.red-color {
    background: #FFFFFF;
    color: #2d2d2d;
}

.header-shop-cart .minicart  .minus,.header-shop-cart .minicart  .plus ,.counter-input .number .minus,.counter-input .number .plus{
    border-radius: 4px;
    padding: 0px 5px 0px 5px;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    cursor: pointer;
}
.header-shop-cart .minicart  .minus ,.counter-input .number .minus{
    font-size: 26px;
}
.header-shop-cart .minicart .number, .counter-input .number{
    margin-top: 5px;
    display: inline-block;
}
.header-shop-cart .minicart input, .counter-input .number input {
    height: 25px;
    width: 30px;
    text-align: center;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: inline-block;
    background-color: transparent;
    padding: 0;
    color: #fff;
    margin-bottom: 0;
}
/* ===================================
        menu card on hover
====================================== */

.user-menu-cart .menu-links {
    background: #191919;
    opacity: 0;
    /*padding: 35px 0 25px 0;*/
    position: absolute;
    right: -15px;
    top: 70px;
    transition: all 0.5s ease 0s;
    width: 230px;
    z-index: 9;
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.3);
    visibility: hidden;
    transform: translateY(10px);
}
.user-menu-cart .menu-links::before {
    position: absolute;
    content: '';
    left: 0;
    top: -25px;
    width: 100%;
    height: 45px;
    display: block;
}
.user-menu-cart:hover .menu-links {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    z-index: 9;
}
.user-menu-cart .menu-links>ul{
    margin-left: 0;
}
.user-menu-cart .menu-links>ul li{
    display: block;
    border-bottom: solid .5px #e9ecef1a;
    position: relative;
    margin-right: 0;
    cursor: pointer;
}
.user-menu-cart .menu-links>ul li:last-child{
    border-bottom: none;
}
.user-menu-cart .menu-links>ul li a{
    font-size: 18px;
    position: relative;
    padding: 15px 25px;
    z-index: 1;
    display: block;
}
.user-menu-cart .menu-links>ul li a i{
    margin-right: 15px;
    transition: .5s ease;
}
.user-menu-cart .menu-links>ul li:hover a i{
    margin-right: 20px;
}
.user-menu-cart .menu-links>ul li .overlay-link{
    background:#ec6d10;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top:0;
    opacity: 0;
    transition: .5s ease;
}
.user-menu-cart .menu-links>ul li:hover .overlay-link{
    opacity: 1;
}

/*==================================*/
/*       listing page form         */
/*==================================*/

.filter-form{
    background-color: #191919;
}
.filter-form .contact-form{
    padding: 20px 0;
}
.filter-form .form-control{
    color: #ffffff;
}
.filter-form .contact-form .form-group{
    display: flex;
    align-items: center;
    margin-bottom: 0;
    width: 90%;
}
.filter-form .contact-form label{
    margin-bottom: 0;
    color: #ffffff;
    position: relative;
    left: -6px;
    top:-1px;
    z-index: 11;
    font-size: 22px;
}
.filter-form .contact-form input{
    color: #ffffff;
    font-size: 16px;
    height: 50px;
    padding: 25px;
}
.filter-form .contact-form .form-control{
    border-radius: 0;
    border:solid 2px transparent;
    margin-bottom: 0;
    border-right: solid 2px #eceef2;
    background-color: transparent;
}
.filter-form .contact-form .form-control:last-child{
    border-right: none;
}
.filter-form .contact-form input::placeholder{
    color: #fff;
    font-size: 16px;
}

.filter-form .form-control:focus {
    border-color: #80bdff00;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

.filter-form .select2-container {
    width: 100% !important;
}

.filter-form .select2-container--default .select2-selection--single {
    background-color: transparent;
    border:none;
    border-radius: 0;
    width: 100%;
}

.filter-form .select2-container--default .select2-selection--single .select2-selection__rendered{
    color: #ffffff;
}
.product-filter-nav {
    transition: .5s ease;
    padding-top: 60px;
}

.change-position.product-filter-nav{
    position: fixed;
    left: 0;
    right: 0;
    top: 83px;
    background: #ec6d10;
    animation: .8s fadeIn;
    padding-top: 0;
    z-index: 11;
}
.change-position.filter-form .main-btn{
    /*padding: 15px 35px;*/
    background: #ffffff ;
    color: #191919 !important;
    transition: .5s ease;
}
.change-position.filter-form .main-btn:hover{
    background: #191919;
    color: #ffffff !important;
}
.product-price{
    width: 90%;
}
.product-filter-nav .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: #FFFFFF transparent #ffffff transparent;
}
/*.select2-dropdown {*/
/*    background-color: #191919;*/
/*}*/
/*.select2-dropdown .select2-container--default .select2-results__option[aria-selected=true] {*/
/*    background-color: #2d2d2d;*/
/*}*/
/*.select2-dropdown .select2-results__option {*/
/*    color: #ffffff;*/
/*}*/
/* .select2-container--default .select2-results__option--highlighted[aria-selected] {*/
/*    background-color: #2D2D2D;*/
/*}*/
/*.select2-container--default .select2-search--dropdown .select2-search__field {*/
/*    border: 1px solid #aaa;*/
/*    background-color: #444;*/
/*}*/


.select2-dropdown{
    background-color: #ffffff;
}
.select2-dropdown.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #dedede;
}
.select2-dropdown .select2-results__option {
    color: #191919;
}
.select2-container--default .select2-dropdown  .select2-results__option--highlighted[aria-selected] {
    background-color: #e3e3e3;
    color: #191919;
}
.select2-container--default .select2-dropdown  .select2-search--dropdown .select2-search__field {
    border: 1px solid #d2d2d2;
    background-color: #e3e3e3;
}
/*=======================
        mini menu
========================*/
.float-middle-logo{
    position: absolute;
    margin-left: 39px;
    top:30px;
    transform: translateX(-50%);
    z-index: 11;
}
.bottom-mini-nav{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #2D2D2D;
    padding: 10px;
}
.bottom-mini-nav .navbar{
    width: 50%;
    margin: auto;
}
/*=======================
          Login
========================*/

.login-content{
    max-width: 450px;
    margin: 0 auto;
    padding: 15px;
}

.login-side-background{
    background-size: cover;
    background-position: center;
    height: 100vh;
}
.login-content .form-control:focus{
    background-color: #363636;
    color: #fff;
}

.login-content h3{
    font-size: 1.85rem;
    line-height: 1.3;
    font-family: 'Quicksand',sans-serif;
}

.login-content .alert {
    position: relative;
    padding: 6px 12px;
    background-color: #fff8e1;
    border-color: #f1cb4b;
    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 1.5rem;
}

.login-content .alert-dismissible .close {
    padding: 0.4rem 1.25rem;
    font-weight: 400;
}

.login-content input{
    width: 100%;
    padding: 9px 20px;
    text-align: left;
    height: 42px;
    outline: 0;
    border-radius: 6px;
    font-size: 15px;
    font-weight: 300;
    border: 0;
    background-color: #363636;
    color: #ffffff;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    margin-bottom: 14px;
}
.login-content input::placeholder{
    color: #ffffff;
    font-size: 12px;
}
.login-content .form-button{
    margin-top: 2.2rem;
}

.login-content .form-button a{
    font-weight: 500;
    margin-left: 2rem;
    float: right;
    margin-top: 15px;
}

.login-sec{
    height: 100vh;
}
.login-content .foot-line{
    margin-top: 50px;
    display: block;
    padding-bottom: 10px;
    font-size: 16px;
    color: #ffffff;
    opacity: .7;
    transition: .5s ease;
    font-weight: 300;
}
.login-content .foot-line:hover{
    opacity: 1;
}

/*=================================*/
/*        account page css         */
/*=================================*/

.account-sec{
    background-color: #191919;
}
.account-sec .user-globe-details{
    background-color: #2D2D2D;
    padding: 20px 0;
}
.account-sec .user-globe-details .img-holder{
    height: 120px;
    width: 120px;
    margin: auto;
}
.account-sec .user-globe-details .img-holder img{
    width: 100%;
}
.account-sec .user-globe-details .user-name{
    background:#ec6d10;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 26px;
    margin-top: 20px;
}
.account-sec .user-globe-details .user-number{
    color: #ffffff;
    font-size: 14px;
}
.account-sec .menu-tabs{
    margin-top: 5px;
}
.account-sec .menu-tabs .nav-tabs{
    border-bottom: none;
}


/*=================================*/
/*           Detail page           */
/*=================================*/
.detail-page-sec{
    background-color: black;
}
.detail-page-sec .nav-pills{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 100px;
    margin: auto;
}
.nav-pills .nav-link.active {
    color: #ec6d10 !important;
    background-color: transparent !important;
}

/*list css*/
.detail-page-sec .food-list{
    padding: 25px 20px;
    font-family: 'Open Sans' , sans-serif;
    cursor: pointer;
    background-color: #2D2D2D;
    -webkit-transition: all .5s ease-out;
    -o-transition: all .5s ease-out;
    transition: all .5s ease-out;
    margin-bottom: 20px;
    position: relative;
    width: 255px;
    margin-right: 20px;
    border-radius: 10px;
}
.tab-content > .active {
    display: flex;
    /* Additional flex properties can be added here based on your requirements */
}
.products-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}
.detail-page-sec .food-list .list-overlay{
    /* background:#ec6d10; */
    position: absolute;
    left: 0;
    right: 100%;
    top: 0;
    bottom: 0;
    transition: .8s ease;
    border-radius: 10px;
}
.filter-sec {
    display: flex;
    flex-direction: column;
    padding-top: 250px;
}
.filter-option {
    cursor: pointer;
    color: #171717;
    width: 200px;
    border-radius: 5px;
}
.detail-page-sec .food-list:hover .list-overlay{
    right: 0;
}
.detail-page-sec .food-list .rates{
    flex-direction: column;
    position: relative;
    z-index: 1;
}
.detail-page-sec .food-list .info img {
    width: 100%;
    height: 150px;
    margin-bottom: 22px;
    border-radius: 10px;
}
.detail-page-sec .food-list .info .sub-heading{
    font-size: 15px;
    margin-top: 15px;
    font-family: Quicksand, sans-serif;
}
.detail-page-sec .food-list .info .main-heading{
    font-size: 20px;
    color: #ffffff;
    font-weight: 400;
    text-align: center;
}
.detail-page-sec .food-list .rates .rate{
    font-size: 26px;
    margin-top: 15px;
    font-weight: 500;
    color: #fff;

}

.line {
    background-color: #171717;
    height: 2px; /* Adjust the height of the line as needed */
    width: 100%;
  }
/***********************************/
/********standalone page css********/
/***********************************/
.secondary-pages-banner {
    background-color: #202020;
    height:26vh;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.secondary-pages-banner .row{
    height: 65vh;
}
.secondary-pages-banner .secondary-item1{
    max-width: 265px;
    position: absolute;
    right: 0;
    top: 46%;
    transform: translate(40%,-50%);
}
.secondary-pages-banner .secondary-item2{
    max-width: 250px;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-31%,0);
    z-index: 1;
}
.secondary-pages-banner .heading-area{
    margin-top: 115px;
}
.secondary-pages-banner .heading-area .heading{
    margin-bottom: 10px;
    background:#ec6d10;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.crumbs{
    position: relative;
}
.crumbs .breadcrumb-items{
    display: inline-block;
}
.crumbs .breadcrumb{
    margin-bottom: 0;
    border-radius: 0;
    padding:0;
    background-color: transparent;
}
.breadcrumb-item+.breadcrumb-item::before {
    color: #FFFFFF;
    opacity: .7;
}

.crumbs .breadcrumb li a{
    color: #ffffff;
    font-size: 16px;
    font-family: 'Quicksand', sans-serif;
    text-decoration: none;
    border-bottom: solid 1px transparent;
    transition: .5s ease;
    padding-bottom: 5px;
    opacity: .7;
}
.crumbs .breadcrumb li a:hover{
    /*color: #ec6d10;*/
    opacity: 1;
}

/*crumbs end*/

.standalone-row .stand-img-des{
    padding-left: 30px;
    text-align: center;
}
.standalone-row:nth-child(2) .stand-img-des{
    padding-left: 0;
    padding-right: 30px;
}
.standalone-detail .sub-heading{
    color: #ec6d10;
    margin-bottom: 20px;
}
.standalone-area .sub-heading{
    color: #ec6d10;
    margin-bottom: 8px;
}
.standalone-detail .heading{
    margin-bottom: 20px;
    font-size: 40px;
    color: #202020;
    font-weight: 500;
    /*font-family: Quicksand , sans-serif;*/
}

.standalone-detail .para_text{
    color: #202020;
    font-weight: 300;
    font-family: Quicksand , sans-serif;
    text-align: center;
    font-size: 15px;
    margin: 0 auto;
    line-height: 2.1;
}

.standalone-detail{
    padding-bottom: 100px;
}
.standalone-area .gradient-text1{
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    margin-bottom: 12px;
}
.standalone-area .stand-img-des .para_text{
    font-family: 'Quicksand', sans-serif;
    color: #202020;
    font-weight: 300;
    opacity: .8;
}


/* ===================================
    Media Queries
   ====================================== */

@media (min-width:992px) and (max-width: 1250px){

    .banner-slider .banner-slider-row {
        height: 100vh;
        padding-top: 90px;
    }
    .banner-slider .banner-social {
        display: none;
    }
}
@media (min-width:992px) and (max-width: 1070px){
    header .upper-nav .top-social-links{
        margin-right: 50px;
    }
}

@media (max-width:991px){
    /*custom css*/
    .heading {
        font-size: 55px;
    }
    .tp-caption.tp-hidden-caption, .tp-forcenotvisible, .tp-hide-revslider, .tp-parallax-wrap.tp-hidden-caption {
        visibility: visible!important;
        display: block!important;
    }
    header .user-links {
        margin-right: 45px;
    }
    /*slider css*/

    .slider-form {
        position: relative;
        z-index: 11;
        top: auto;
        transform: translateY(0%);
        left: auto;
        right: auto;
        padding-top: 80px;
        background-color: #191919;
    }
    .slider-form .contact-form {
        background-color: transparent;
        width: 90%;
    }
    .slider-form .contact-form .form-group {
        margin-bottom: 20px;
    }
    .slider-form .contact-form label {
        position: absolute;
        left: 0;
    }
    .slider-form .contact-form .form-group:last-child{
        margin-bottom: 50px;
    }
    .slider-form .contact-form .form-group input{
        border-bottom: solid 1px #eeeeee;
        padding-left: 30px;
    }
    /*heading-area*/
    .gallery-sec .heading-area,.app-sec .heading-area,.about-sec .heading-area{
        width: 100%;
    }
    /*gallery css*/

    .gallery-sec .mini-services .mini-service-card {
        margin-bottom: 50px;
    }
    .gallery-sec .mini-services>.row>div:last-child .mini-service-card {
        margin-bottom: 0;
    }
    /*app sec*/
    .app-sec .apps-details .app-l {
        margin-bottom: 0;
    }
    .app-sec .apps-details>div:last-child .app-l {
        margin-bottom: 0;
    }
    .app-sec .heading{
        margin-bottom: 0;
    }
    .app-sec .apps-details {
        width: 100%;
    }
    /*about sec*/
    .features .feature-card {
        width: 50%;
        margin: auto;
        margin-bottom: 50px;
    }
    .features>div:last-child .feature-card {
        margin-bottom: 0;
    }

    /*footer*/
    .company-about {
        margin-top: 10px;
    }


    /*==================================*/
    /*        secondary banner css      */
    /*==================================*/

    .secondary-pages-banner .heading-area {
        margin-top: -50px;
    }

    /*==================================*/
    /*           Detail page css        */
    /*==================================*/
  
    /*==================================*/
    /*        Accounts page css       */
    /*==================================*/
    .account-sec .menu-bar{
        margin-bottom: 50px;
    }
    .account-sec .main-content{
        border-top: solid 8px;
        padding-top: 50px;
        text-align: center;
    }

    /*==================================*/
    /*        standalone page css       */
    /*==================================*/
    .standalone-row:not(:last-child) {
        margin-bottom: 8rem;
    }

    .standalone-area .sub-heading {
        margin-top: 20px;
    }

    .standalone-row:nth-child(2) .stand-img-des {
        padding-left: 18px;
        padding-right: 18px;
    }

    .standalone-row .stand-img-des {
        padding-left: 18px;
        padding-right: 18px;
    }
}


@media screen and (max-width:767px){
    .bottom-mini-nav .navbar{
        width: 100%;
        margin: auto;
        display: block;
        text-align: center;
    }
    header .user-links {
        margin-right: 26px;
    }
    .login-sec{
        height: auto;
    }
    .float-middle-logo {
        position: absolute;
        margin-left: 0;
        top: 0;
        left: 50%;
        transform: translate(-50%,-50%);
    }


    /*app sec*/
    .app-sec .apps-details .app-l {
        margin-bottom: 80px;
    }
    .features .feature-card {
        margin: auto;
        margin-bottom: 50px;
        width: 100%;
    }
    /*footer*/
    .company-about {
        margin-top: 10px;
    }

    .mo-fire {
        left:45%;
        top: 100px;
      }

      .leaflet-container.leaflet-touch-drag.leaflet-touch-zoom  {
        width: 365px !important;
      }
    /***********************************/
    /********standalone page css********/
    /***********************************/
    .slider-sec .overlay h4 {
        font-size: 45px;
    }
    .standalone-row:not(:last-child) {
        margin-bottom: 8rem;
    }
    .standalone-area .sub-heading{
        margin-top: 20px;
    }
    .standalone-row:nth-child(2) .stand-img-des {
        padding-left: 18px;
        padding-right: 18px;
    }
    .standalone-row .stand-img-des {
        padding-left: 18px;
        padding-right: 18px;
    }
    .products-container {
        flex-direction: column;
    }
    .secondary-pages-banner {
        height: 17vh;
    }
    .secondary-pages-banner .heading-area {
        margin-top: 69px;
    }
    .detail-page-sec .nav-pills {
        padding-top: 10px;
    }
    .detail-page-sec .food-list{
        width: 100%;
    }
    .detail-page-sec .food-list .info img {
        height: 240px;
    }
    .filter-sec {
        flex-direction: row;
        padding-top: 20px;
        flex-wrap: wrap;
        justify-content: center;
    }
    .filter-option {
        width: 120px;
        font-size: 11px;
    }
    .select-filter {
        padding-left: 10px;
    }
    .filter-category {
        padding-right: 30px;
    }
}


@media (max-width: 992.99px) {
    .login-content .form-button a {
        margin-left: 0;
        float: none;
        margin-top: 20px;
        display: block;
        text-align: center;
    }
    .login-side-background{
        height: 300px;
    }
    .login-content {
        margin-top: 50px;
        padding-bottom: 220px;
    }
    .product-filter-nav {
        position: relative;
        top: auto;
    }
    .filter-form .contact-form .form-group {
        width: 95%;
        margin: auto;
        margin-bottom: 20px;
    }
    .product-price {
        width: 95%;
        margin: auto;
    }
    .header-shop-cart .minicart{
        right: 9px;
    }
    .user-menu-cart .menu-links {
        right: 25px;
    }
    
}
@media (max-width: 576px) {
    .sub-heading {
        font-size: 18px;
    }
    .heading {
        font-size: 45px;
    }
    header .user-links {
        margin-right: 29%;
    }
    .login-side-background{
        height: 200px;
    }
    .secondary-pages-banner .secondary-item1 {
        max-width: 140px;
        top: 84%;
        transform: translate(40%,-50%);
    }
    .secondary-pages-banner .secondary-item2 {
        max-width: 179px;
    }
    header .main-navigation {
        padding-top: 17px;
    }
    .login-content .foot-line{
        margin-top: 20px;
        display: block;
    }
}
@media (min-width: 576px) {
    #slide-1-layer-6{
        visibility: visible!important;
        display: block!important;
    }
}
